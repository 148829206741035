import './common-styles';
import '@/styles/dmq-products-section.scss';
import '@/styles/dmq-product-list.scss';
import '@/styles/dmq-product-card.scss';
import '@/styles/dmq-filters.scss';
import '@/styles/dmq-pager.scss';
import '@/styles/dmq-breadcrumb.scss';
import '@/styles/dmq-retailer.scss';
import 'leaflet/dist/leaflet.css';

import './common-code';
import store from '@/store/filters';
import DmqFilters from '@/components/DmqFilters.vue';
import DmqProductSortSelect from '@/components/DmqProductSortSelect';
import { getModule } from 'vuex-module-decorators';
import FilterModule from '@/store/modules/filter-module';
import DmqToggler from '@/components/DmqToggler';
import DmqMap from '@/components/DmqMap';

import '@/components/DmqAccordion';


const filtersEl = document.querySelector('#dmq-filters') as HTMLElement;
if (filtersEl) {
  const categoryFilterTitle = filtersEl.dataset.categoryFilterTitle || '';
  const priceFilterTitle = filtersEl.dataset.priceFilterTitle || '';
  const filtersData = JSON.parse(filtersEl.dataset.filters || '{}');
  getModule(FilterModule, store).filtersFetched(filtersData);
  new DmqFilters({
    store,
    propsData: {
      categoryFilterTitle,
      priceFilterTitle,
    },
  }).$mount(filtersEl);
}

const sortEl = document.querySelector('.dmq-product-sort-select') as HTMLSelectElement;
if (sortEl) {
  getModule(FilterModule, store).sortValueChanged(sortEl.options[sortEl.selectedIndex].value);
  const _ = new DmqProductSortSelect(sortEl);
}

const pfButtonEl = document.querySelector('#dmq-product-filters-button') as HTMLElement;
const pfContainerEl = document.querySelector('#dmq-product-filters-container') as HTMLElement;
const pfCloseButtonEl = document.querySelector('#dmq-product-filters-close-button') as HTMLElement;
if (pfButtonEl && pfContainerEl && pfCloseButtonEl) {
  const _ = new DmqToggler(
    [pfButtonEl, pfCloseButtonEl],
    pfContainerEl,
    null,
    'dmq-products-section__aside--toggled');
}

const map = new DmqMap(document.getElementById('map') as HTMLElement);
const mapToggle = document.getElementById('dmq-retailer-map-toggle-mobile') as HTMLElement;
mapToggle.addEventListener('click', (ev) => {
  map.map.invalidateSize();
});
